import React from 'react';
import { styled } from "linaria/react";
import MaxWidth from '../../Layout/MaxWidth';
import { ProductGrid } from '../../CategoryPage/ProductGrid';
import t from '@jetshop/intl';
import { Title } from './StartPageCategories';

import { theme } from "../../Theme";

const Container = styled('div')`
  padding-top: 48px;
  padding-bottom: 28px;
  background-color: #f7f7f7;
  ${theme.below.sm} {
    padding-top: 32px;
    padding-bottom: 4px;
  }
`;

// The horizontal scroll is styled in a way that on any resolution smaller than 'lg', a part of the second/third item can be seen, indicating it is a horizontally scrollable container.
const StyledProductGrid = styled(ProductGrid)`
  flex-wrap: nowrap;
  ${theme.below.md} {
    overflow-x: auto;
    .product-card {
      width: 300px;
      flex-shrink: 0;
      max-width: 40vw;
    }
  }
  ${theme.below.sm} {
    .product-card {
      max-width: 60vw;
      width: 340px;
    }
  }
  ${theme.below.xs} {
    .product-card {
      max-width: 75vw;
    }
  }

  @supports (display: grid) {
    grid-template-columns: repeat(4, 1fr);
    margin: 0;
    ${theme.below.md} {
      grid-template-columns: repeat(10, 1fr);
    }
  }
`;

const StartPageProductGrid = props => (
  <Container>
    <MaxWidth>
      <Title>{t('Selected Products')}</Title>
      <StyledProductGrid
        {...props}
        imageSizes={[1 / 2, 1 / 2, 1 / 4]}
        list={'Start page - Selected products'}
      />
    </MaxWidth>
  </Container>
);

export default StartPageProductGrid;
