import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import LoadingPage from '../LoadingPage';
import StartPageRenderer from '@jetshop/ui/StartPageRenderer';
import StartPageProductGrid from './Content/StartPageProductGrid';
import startPageQuery from './StartPageQuery.gql';
import { theme } from '../Theme';
import { allContentComponents } from '../ContentComponents/ContentComponents';


const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MainSectionWrapper = styled(MaxWidth)`
  width: 100%;
  align-self: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  ${theme.below.sm} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StartPage = ({ startPageId }) => {
  return (
    <Fragment>
      <StartPageWrapper>
        <Query variables={{ startPageId: startPageId }} query={startPageQuery}>
        {/*<Query variables={{ startPageId: 176 }} query={startPageQuery}>*/}
          {result => {
            const { loading, data } = result;
            if (loading) return <LoadingPage />;
            if (!data) return null;
            return (
              <>
                <StartPageRenderer
                  result={result}
                  startPageComponents={allContentComponents}
                  ProductGrid={StartPageProductGrid}
                  LoadingPage={LoadingPage}
                />
              </>
            );
          }}
        </Query>
      </StartPageWrapper>
    </Fragment>
  );
};

export default StartPage;
