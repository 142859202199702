import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import { theme } from '../Theme';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { useQuery } from 'react-apollo';
import SiblingQuery from './SiblingQuery.gql';
import LoadingPage from '../LoadingPage';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { allContentComponents } from '../ContentComponents/ContentComponents';


const BlogContentWrapper = styled('div')`
  max-width: 90rem;
  margin: 2rem auto;
  display: flex;
  gap: 2rem;
  padding: 0 2rem;
  justify-content: center;

  ${theme.below.lg} {
    margin: 0 auto;
    padding: 0;
    flex-direction: column;
  }
`;

const MainColumn = styled('div')`
  width: 75%;

  ${theme.below.lg} {
    border-bottom: 1px solid ${theme.colors.lightgrey};
    padding-bottom: 2rem;
    width: 100%;
  }

  p {
    font-size: 14px;
    letter-spacing: initial;
    margin: 0;
  }

`;

const BlogHeader = styled('div')`
  padding: 0 0 1rem;

  h1 {
    text-transform: uppercase;
    color: ${theme.colors.darkBeige};
    font-weight: 500;
    font-size: 16px;
    margin: 1rem 0 0.5rem;
    line-height: 1.1;

    ${theme.below.lg} {
      padding: 0 1rem;
    }
  }

  h2 {
    text-transform: uppercase;
    color: ${theme.colors.black};
    font-weight: 400;
    font-size: 12px;
    margin: 0;
    line-height: 1.1;

    ${theme.below.lg} {
      padding: 0 1rem;
    }
  }
`;

const ImageContainer = styled('div')`
  background: ${theme.colors.lightgrey};
  padding-top: 56.25%;
  position: relative;

  > div {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  svg {
    max-width: 10rem;
    max-height: 10rem;
    display: block;
  }
`;

const ContentRendererWrapper = styled('div')`
  p {
    ${theme.below.lg} {
      padding: 0 1rem;
    }
  }
`;

const SiblingContainer = styled('aside')`
`;

const Sibling = styled('article')`
  a {
    text-decoration: none;
    color: ${theme.colors.black};
    display: block;
    margin: 0 0 1.25rem;


    h3 {
      text-transform: uppercase;
      color: ${theme.colors.black};
      font-weight: 400;
      font-size: 0.75rem;
      margin: 0;
      line-height: 1.1;
    }

    h2 {
      text-transform: uppercase;
      color: ${theme.colors.darkBeige};
      font-weight: 400;
      font-size: 1.25rem;
      margin: 0.5rem 0 0;
      line-height: 1.1;
    }
  }

`;


const Siblings = ({ category }) => {
  const { data, loading, error } = useQuery(SiblingQuery, {
    variables: {
      id: category?.parent?.id
    },
    errorPolicy: 'ignore'
  });

  if (error) return null;
  if (loading) return <LoadingPage />;

  return (
    <SiblingContainer>
      {data?.category?.subcategories?.map((sibling, index) => {
        return category.id !== sibling.id && (
          <Sibling key={`sibling-${index}`}>
            <CategoryLink category={sibling}>
              <h3>{sibling.mainHeader}</h3>
              <h2>{sibling.name}</h2>
            </CategoryLink>
          </Sibling>
        );
      })}
    </SiblingContainer>
  );
};

const BlogContentPage = ({ category }) => {
  const headerContent = category?.data?.items?.[0]?.properties;
  const headerImageUrl = headerContent?.[0]?.value?.value ?? category.images?.[4]?.url;
  const mainHeading = headerContent?.[1]?.value?.value ?? category.name;
  const subHeading = headerContent?.[2]?.value?.value ?? category.mainHeader;
  const blogContentItems = category?.data?.items && category?.data?.items.length > 1 ? category.data.items.slice(1) : [];

  return (
    <BlogContentWrapper>
      <MainColumn>
        <BlogHeader>
          {headerImageUrl && (
            <ImageContainer>
              <div>
                <Image src={headerImageUrl}
                       sizes={1}
                       alt={mainHeading}
                       aspect='16:9'
                       cover
                       fillAvailableSpace
                />
              </div>
            </ImageContainer>
          )}
          <h1>{mainHeading}</h1>
          <h2>{subHeading}</h2>
        </BlogHeader>
        <ContentRendererWrapper>
          <ContentRenderer items={blogContentItems} components={allContentComponents} />
        </ContentRendererWrapper>
      </MainColumn>
      <Siblings category={category} />
    </BlogContentWrapper>
  );
};

export default BlogContentPage;