import React from 'react';
import { styled } from "linaria/react";
import MaxWidth from '../../Layout/MaxWidth';
import Image from '@jetshop/ui/Image/Image';
import StartPageCategoriesQuery from './StartPageCategoriesQuery.gql';
import { Query } from 'react-apollo';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { theme } from "../../Theme";

const Container = styled('div')``;

export const Title = styled('h2')`
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0.75rem;
  ${theme.below.md} {
    font-size: 18px;
  }
`;

const ItemTitle = styled('h2')`
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  color: #000;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: 200ms;
  ${theme.below.md} {
    font-size: 16px;
  }
`;

const ImageContainer = styled('div')`
  /* height: 218px; */
  transition: 200ms;
  filter: grayscale(1);
`;

const Item = styled(CategoryLink)`
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  ${ItemTitle} {
    background-color: white;
  }
  &:hover {
    ${ItemTitle} {
      background-color: #2f80ed;
      color: white;
    }
    ${ImageContainer} {
      filter: grayscale(0);
    }
  }

  ${theme.above.lg} {
    flex: 1;
  }

  ${theme.below.lg} {
    width: calc(25% - 24px);
  }

  ${theme.below.md} {
    width: 180px;
    max-width: 24vw;
    flex-shrink: 0;
  }

  ${theme.below.sm} {
    width: 210px;
    max-width: 55vw;
  }
`;

const ItemsContainer = styled('div')`
  display: flex;
  margin-left: -12px;
  margin-right: -12px;
  ${theme.below.lg} {
    flex-wrap: wrap;
    justify-content: center;
  }
  ${theme.below.md} {
    overflow-x: auto;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
`;

// @todo: is there an alternative method to running an individual query for each category?
// @todo: rather than using the category images, assigning individual images in JSON, as category images were meant to be horizontal (hero)

const StartPageCategories = ({ categories }) => {
  return (
    <MaxWidth>
      <Container>
        <ItemsContainer>
          {categories.map((cat, index) => (
            <Query
              variables={{ id: parseInt(cat.id || cat) }}
              query={StartPageCategoriesQuery}
              key={index}
            >
              {({ data }) =>
                data && Object.keys(data).length > 0 ? (
                  <Item category={data.category}>
                    <ImageContainer>
                      <Image
                        aspect={'3:4'}
                        sizes={[1 / 2, 1 / 3, 1 / 4]}
                        cover={true}
                        src={cat.image || data.category.images[0].url}
                      />
                    </ImageContainer>
                    <ItemTitle>{data.category.name}</ItemTitle>
                  </Item>
                ) : null
              }
            </Query>
          ))}
        </ItemsContainer>
      </Container>
    </MaxWidth>
  );
};

export default StartPageCategories;
